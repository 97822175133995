export const assignInsurfinFundSource = async (authToken: any, data: any) => {
  return fetch(
    `${process.env.REACT_APP_DASHBOARD_URL}/application/assign-fundsource-insurfin`,
    {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
};

export const fraudCheckInsurfin = async (authToken: any, data: any) => {
  return fetch(
    `${process.env.REACT_APP_DASHBOARD_URL}/integrations/fraud-check-evaluate`,
    {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
};

export const evaluateInsurfinApplication = async (
  authToken: any,
  data: any
) => {
  return fetch(`${process.env.REACT_APP_DASHBOARD_URL}/application/evaluate`, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      return console.error(error);
    });
};
